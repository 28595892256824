import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { Layout, Spin, Alert } from 'antd'
import {connect} from 'react-redux'

import LeftNav from '../../components/left-nav'
import Header from '../../components/header'
// import Home from '../home/home'
// import Category from '../category/category'
// import Article from '../article'
// import Role from '../role/role'
// import User from '../user/user'
// import Menu from "../menu";
import Bar from '../charts/bar'
import Line from '../charts/line'
import Pie from '../charts/pie'
import NotFound from '../not-found/not-found'
import Loadable from 'react-loadable'

const { Footer, Sider, Content } = Layout;
const Loading = (props) => {
  if (props.error) {
    return <p>Error!</p>;
  } else if (props.pastDelay) {
    return <Spin tip="Loading...">
    <Alert
      message=""
      description=""
      type="info"
    />
  </Spin>
  } else {
    return null;
  }
};
const LoadableHome = Loadable({
  loader: () => import('../home/home'),
  loading: Loading,
  delay: 200
});
const LoadableArticle = Loadable({
  loader: () => import('../article'),
  loading: Loading,
  delay: 200
});
const LoadableCategory = Loadable({
  loader: () => import('../category/category'),
  loading: Loading,
  delay: 200
});
const LoadableRole = Loadable({
  loader: () => import('../role/role'),
  loading: Loading,
  delay: 200
});
const LoadableUser = Loadable({
  loader: () => import('../user/user'),
  loading: Loading,
  delay: 200
});
const LoadableMenu = Loadable({
  loader: () => import('../menu'),
  loading: Loading,
  delay: 200
});


/*
后台管理的路由组件
 */
class Admin extends Component {
  render () {
    const user = this.props.user;
    // 如果内存没有存储user ==> 当前没有登陆
    if(!user || !user.id) {
      // 自动跳转到登陆(在render()中)
      return <Redirect to='/login'/>
    }
    return (
      <Layout style={{minHeight: '100%'}}>
        <Sider>
          <LeftNav/>
        </Sider>
        <Layout>
          <Header>Header</Header>
          <Content style={{margin: 20, backgroundColor: '#fff'}}>
            <Switch>
              <Redirect exact from='/' to='/home'/>
              <Route path='/home' component={LoadableHome}/>
              <Route path='/category' component={LoadableCategory}/>
              <Route path='/article' component={LoadableArticle}/>
              <Route path='/role' component={LoadableRole}/>
              <Route path='/user' component={LoadableUser}/>
              <Route path='/menu' component={LoadableMenu}/>
              <Route path='/charts/bar' component={Bar}/>
              <Route path='/charts/line' component={Line}/>
              <Route path='/charts/pie' component={Pie}/>
              <Route component={NotFound}/> {/*上面没有一个匹配, 直接显示*/}
            </Switch>
          </Content>
          <Footer style={{textAlign: 'center', color: '#cccccc'}}>推荐使用谷歌浏览器，可以获得更佳页面操作体验</Footer>
        </Layout>
      </Layout>
    )
  }
}


export default connect(
  state => ({user: state.user}),
  {}
)(Admin)