/*
要求: 能根据接口文档定义接口请求
包含应用中所有接口请求函数的模块
每个函数的返回值都是promise

基本要求: 能根据接口文档定义接口请求函数
 */
import jsonp from 'jsonp'
import {message} from 'antd'
import ajax from './ajax'
import {BASE_DOMIN} from "../utils/constants";

// const BASE = 'http://localhost:5000'
// const BASE = 'https://file.jkfan.cn/admin'
const BASE = BASE_DOMIN + '/admin'
// 登陆
/*
export function reqLogin(username, password) {
  return ajax('/login', {username, password}, 'POST')
}*/
export const reqLogin = (name, password) => ajax(BASE + '/user/login', {name, password}, 'POST');

// 获取一级/二级分类的列表
export const reqCategorys = (parent_id) => ajax(BASE + '/article/category', {parent_id});

// 添加分类
export const reqAddCategory = (categoryName, parentId) => ajax(BASE + '/manage/category/add', {
    categoryName,
    parentId
}, 'POST');

// 更新分类
export const reqUpdateCategory = (id, name, parent_id) => ajax(BASE + '/article/categoryupdate', {
    id,
    name,
    parent_id
}, 'POST');

// 获取一个分类
export const reqCategory = (id) => ajax(BASE + '/article/categoryinfo', {id});
//获取tag列表
export const reqTags = () => ajax(BASE + '/article/taglist');
//获取文章tag与所有的tag
export const reqArticleTags = (id) => ajax(BASE + '/article/tags', {id});
//更新tag
export const reqUpdateTag = (id, name, parent_id) => ajax(BASE + '/article/tagupdate', {id, name}, 'POST');
//删除tag
export const reqDelTag = (id) => ajax(BASE + '/article/tagdel', {id}, 'POST');
// 获取文章分页列表
export const reqArticles = (params) => ajax(BASE + '/article/list', params);

export const reqDeleteArticle = (id) => ajax(BASE + '/article/delete', {id});

// 更新文章的状态(上架/下架)
export const reqUpdateStatus = (id, status) => ajax(BASE + '/article/updatestatus', {id, status}, 'POST');

// 删除指定名称的图片
export const reqDeleteImg = (url) => ajax(BASE + '/file/delete', {url}, 'POST');

// 添加/修改文章
export const reqAddOrUpdateArticle = (article) => ajax(BASE + '/article/save', article, 'POST');

// 获取所有角色的列表
export const reqRoles = () => ajax(BASE + '/role/list');
export const reqMenus = () => ajax(BASE + '/role/menus');
// 添加角色
export const reqAddRole = (name) => ajax(BASE + '/role/save', {name}, 'POST');
// 添加角色
export const reqUpdateRole = (role) => ajax(BASE + '/role/issue', role, 'POST');


// 获取所有用户的列表
export const reqUsers = (params) => ajax(BASE + '/user/list', params);
// 删除指定用户
export const reqDeleteUser = (id) => ajax(BASE + '/user/delete', {id}, 'POST');
// 添加/更新用户
export const reqAddOrUpdateUser = (user) => ajax(BASE + '/user/save', user, 'POST');

// 获取所有菜单的列表
export const reqAllMenus = (params) => ajax(BASE + '/user/menus', params);
// 删除指定菜单
export const reqDeleteMenu = (id) => ajax(BASE + '/user/menudelete', {id}, 'POST');
// 添加/更新菜单
export const reqAddOrUpdateMenu = (user) => ajax(BASE + '/user/menusave', user, 'POST');


/*
json请求的接口请求函数
 */
export const reqWeather = (city) => {
    
    return new Promise((resolve, reject) => {
        const url = `https://api.map.baidu.com/telematics/v3/weather?location=${city}&output=json&ak=3p49MVra6urFRGOT9s8UBWr2`
        // 发送jsonp请求
        jsonp(url, {}, (err, data) => {
            console.log('jsonp()', err, data);
            // 如果成功了
            if (!err && data.status === 'success') {
                // 取出需要的数据
                const {dayPictureUrl, weather} = data.results[0].weather_data[0];
                resolve({dayPictureUrl, weather});
            } else {
                // 如果失败了
                message.error('获取天气信息失败!');
            }
            
        })
    })
};
// reqWeather('北京')
/*
jsonp解决ajax跨域的原理
  1). jsonp只能解决GET类型的ajax请求跨域问题
  2). jsonp请求不是ajax请求, 而是一般的get请求
  3). 基本原理
   浏览器端:
      动态生成<script>来请求后台接口(src就是接口的url)
      定义好用于接收响应数据的函数(fn), 并将函数名通过请求参数提交给后台(如: callback=fn)
   服务器端:
      接收到请求处理产生结果数据后, 返回一个函数调用的js代码, 并将结果数据作为实参传入函数调用
   浏览器端:
      收到响应自动执行函数调用的js代码, 也就执行了提前定义好的回调函数, 并得到了需要的结果数据
 */